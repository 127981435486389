<template>
  <ValidationProvider
    :name="name"
    :rules="rules"
    tag="label"
    class="relative block"
  >
    <template>
      <label
        class="block text-sm"
        :for="name"
      >
        {{ label | allCaps }}
      </label>
      <div class="relative">
        <select
          class="w-full px-4 py-3 leading-tight border rounded appearance-none bg-jooycar-gray-lightest focus:outline-none focus:shadow-outline"
          :class="`${noDataClasses} ${disabledClasses}`"
          :name="name"
          :id="name"
          type="text"
          :disabled="disabled"
          v-model="currentValue"
          v-bind="$attrs"
          @focus="focused = true"
          @blur="focused = false"
        >
          <option
            value=""
            disabled
            selected
          >
            {{ placeholder }}
          </option>
          <option
            v-for="(option, index) in options"
            :key="index"
            :value="fullValue ? option : option.value"
          >
            {{ option[labelName] }}
          </option>
        </select>
        <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg
            class="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          ><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
    </template>
  </ValidationProvider>
</template>

<script>
export default {
  data() {
    return {
      currentValue: this.value,
      focused: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    fullValue: {
      type: Boolean,
      default: false,
    },
    labelName: {
      type: String,
      default: 'label',
    },
  },
  computed: {
    disabledClasses() {
      return this.disabled ? 'opacity-25 cursor-not-allowed bg-jooycar-gray-light' : '';
    },
    noDataClasses() {
      return !this.currentValue && !this.focused ? 'text-jooycar-gray-light' : '';
    },
  },
  filters: {
    allCaps(val) {
      return val.toUpperCase();
    },
  },
  watch: {
    currentValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.currentValue = val;
    },
  },
};
</script>
