<template>
  <ValidationProvider
    :name="name"
    :rules="rules"
    class="relative block"
  >
    <template slot-scope="{ errors }">
      <label
        class="block text-sm"
        :for="name"
      >
        {{ label | allCaps }}
      </label>
      <v-date-picker
        v-model="currentValue"
        :locale="calendarLocale"
        :input-props="{ placeholder: placeholder }"
        :popover="{ placement: 'auto', visibility: 'focus' }"
        :max-date="maxDate"
      >
        <input
          :id="name"
          :class="[disabledClasses, dateColor]"
          slot-scope="{ inputProps, inputEvents }"
          v-bind="inputProps"
          v-on="inputEvents"
          class="w-full px-4 py-3 leading-tight border rounded placeholder-jooycar-gray-light bg-jooycar-gray-lightest focus:outline-none focus:shadow-outline"
          :name="name"
          type="text"
          :disabled="disabled"
          @focus="focused = true"
          @blur="focused = false"
        >
      </v-date-picker>
      <div
        class="text-sm text-red-400"
        v-if="errors.length > 0"
      >
        {{ errors[0] }}
      </div>
    </template>
  </ValidationProvider>
</template>

<script>
const VDatePicker = () => import('v-calendar/lib/components/date-picker.umd');

export default {
  components: {
    VDatePicker,
  },
  data() {
    return {
      currentValue: this.value,
      focused: false,
      calendarLocale: {
        id: 'es-CL',
        firstDayOfWeek: 2,
        masks: {
          input: ['DD/MM/YYYY'],
        },
      },
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Date],
      default: '',
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    dateColor: {
      type: String,
      default: '',
    }
  },
  filters: {
    allCaps(val) {
      return val.toUpperCase();
    },
  },
  watch: {
    currentValue(val) {
      this.$emit('input', val);
    },
    value() {
      if (!this.currentValue || (this.value && this.value.getTime() !== this.currentValue.getTime())) {
        this.currentValue = new Date(this.value);
      }
    },
  },
  computed: {
    disabledClasses() {
      return this.disabled ? 'opacity-25 cursor-not-allowed bg-jooycar-gray-light' : '';
    },
  },
};
</script>
