<template>
  <div class="backgroud-img">
    <div class="sticky top-0 z-10 mb-10">
      <stepper :current-step="4"/>
      <step-progress-bar :step="4"/>
    </div>
    <ValidationObserver
      ref="form"
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        class="flex flex-col flex-1 px-12 pb-12"
        @submit.prevent="handleSubmit(goToPayment)"
      >
        <div class="flex items-center justify-center mb-10">
          <toggle-button
            @change="toggleDifferentInsuredAndPayingPersonData"
            :value="differentInsuredAndPayingPersonData"
            :sync="true"
            color="#4066B3"
            :height="32"
            :width="56"
            class="mr-5"
            id="paying-data-toggle"
          />
          <label
            @click="toggleDifferentInsuredAndPayingPersonData"
            class="text-sm uppercase"
          >
            Activar si el pagador es diferente al asegurado
          </label>
        </div>
        <div
          class="flex-1"
          v-if="differentInsuredAndPayingPersonData"
        >
          <div
            class="mt-8 mb-8 text-3xl font-bold text-center text-jooycar-blue-dark"
          >
            Datos de Pagador
          </div>
          <div class="flex items-center mb-10">
            <toggle-button
              @change="toggleIsPayingPersonCompany"
              :value="isPayingPersonCompany"
              :sync="true"
              color="#4066B3"
              :height="32"
              :width="56"
              class="mr-5"
            />
            <label
              @click="toggleIsPayingPersonCompany"
              class="text-sm uppercase"
            >
              Activar si el propietario del vehículo es una empresa
            </label>
          </div>
          <div class="flex flex-col flex-wrap w-full max-w-full md:flex-row">
            <data-input
              class="flex flex-col flex-1 mt-3 md:mr-4"
              label="Rut*"
              name="idValue"
              placeholder="11.111.111-1"
              v-model="payingPerson.idValue"
              :rut="true"
              rules="required|rut"
              :max-length="12"
            />
            <data-input
              class="flex flex-col flex-1 mt-3 md:pr-4"
              label="Nombre*"
              name="name"
              placeholder="Juan"
              v-model="payingPerson.firstName"
              rules="required"
            />
            <data-input
              class="flex flex-col flex-1 mt-3 md:pr-4"
              label="Apellido Paterno*"
              name="firstLastName"
              placeholder="Pérez"
              v-model="payingPerson.lastName1"
              :disabled="isPayingPersonCompany"
              :rules="{ required_if: !isPayingPersonCompany }"
            />
            <data-input
              class="flex flex-col flex-1 mt-3"
              label="Apellido Materno*"
              name="secondLastName"
              placeholder="Soto"
              v-model="payingPerson.lastName2"
              :disabled="isPayingPersonCompany"
              :rules="{ required_if: !isPayingPersonCompany }"
            />
          </div>
          <div class="flex flex-col flex-wrap w-full md:flex-row">
            <data-select
              class="flex flex-col flex-1 mt-3 md:pr-4"
              label="Género*"
              name="gender"
              v-model="payingPerson.gender"
              placeholder="Seleccionar"
              :options="genders"
              :disabled="isPayingPersonCompany"
              :rules="{ required_if: !isPayingPersonCompany }"
            />
            <data-input
              class="flex flex-col flex-1 max-w-full mt-3 md:pr-4"
              label="Teléfono*"
              name="mobile"
              placeholder="12345678"
              v-model="payingPerson.mobile"
              rules="digits:8"
              text-left="+569"
              :process="value => value.replace(/\D/g,'')"
              :max-length="8"
            />
            <data-date
              class="flex flex-col flex-1 mt-3 md:pr-4 whitespace-nowrap"
              label="Fecha de nacimiento*"
              name="birthDate"
              placeholder="DD/MM/AAAA"
              v-model="payingPerson.birthDate"
              rules="required"
              :disabled="isPayingPersonCompany"
              :max-date="new Date()"
              :dateColor="isPayingPersonCompany ? 'transparent-text' : ''"
            />
            <data-input
              class="flex flex-col flex-1 mt-3"
              label="Email*"
              name="mail"
              placeholder="juanitop@gmail.com"
              v-model="payingPerson.email"
              rules="required|email"
            />
          </div>
          <address-input
            class="mb-3"
            v-model="payingAddress"
            input-id-prefix="paying-address"
          />
          <div class="flex justify-center mb-10 text-sm text-jooycar-gray-dark">
            <p>* Campos obligatorios</p>
          </div>
        </div>
        <div
          class="mt-8 mb-12 text-3xl font-bold text-center text-jooycar-blue-dark"
        >
          Resumen y Pago
        </div>
        <div class="flex-1">
          <div class="grid items-center grid-cols-1 mb-12 gap-x-5 gap-y-3 md:grid-cols-3">
            <div class="h-full pt-4 pb-8 pl-6 bg-jooycar-gray-lightest">
              <inline-svg
                :src="require('../assets/images/icons/user.svg')"
                class="w-6 h-6 mb-2 text-jooycar-gray-medium"
              />
              <div class="mb-2 font-semibold text-md">
                Datos del propietario
              </div>
              <div class="text-sm capitalize">
                {{ insuredPersonFullName }}
              </div>
              <div class="text-sm">
                {{ insuredPerson.idValue }}
              </div>
            </div>
            <div class="h-full pt-4 pb-8 pl-6 bg-jooycar-gray-lightest">
              <inline-svg
                :src="require('../assets/images/icons/car.svg')"
                class="w-6 h-6 mb-2 text-jooycar-gray-medium"
              />
              <div class="mb-2 font-semibold text-md">
                Datos del vehículo
              </div>
              <div class="text-sm">
                {{ insuredVehicle.plate }}
              </div>
              <div class="text-sm">
                {{ carName }}
              </div>
            </div>
            <div class="h-full pt-4 pb-8 pl-6 bg-jooycar-gray-lightest">
              <inline-svg
                :src="require('../assets/images/icons/telephone.svg')"
                class="w-6 h-6 mb-2 text-jooycar-gray-medium"
              />
              <div class="mb-2 font-semibold text-md">
                Datos de contacto
              </div>
              <div class="text-sm">
                <inline-svg
                  :src="require('../assets/images/icons/phone.svg')"
                  class="inline-block w-3 h-3 mr-2 text-jooycar-gray-medium"
                />
                {{ insuredPersonFullMobile | formatPhone }}
              </div>
              <div class="text-sm">
                <inline-svg
                  :src="require('../assets/images/icons/email.svg')"
                  class="inline-block w-3 h-3 mr-2 text-jooycar-gray-medium"
                />
                {{ insuredPerson.email }}
              </div>
            </div>
            <div class="h-full pt-4 pb-8 pl-6 bg-jooycar-gray-lightest">
              <inline-svg
                :src="require('../assets/images/icons/inspection.svg')"
                class="w-6 h-6 mb-2 text-jooycar-gray-medium"
              />
              <div class="mb-2 font-semibold text-md">
                Datos para inspección
              </div>
              <div class="text-sm">
                <inline-svg
                  :src="require('../assets/images/icons/phone.svg')"
                  class="inline-block w-3 h-3 mr-2 text-jooycar-gray-medium"
                />
                {{ inspectionPersonFullMobile | formatPhone }}
              </div>
              <div class="text-sm">
                <inline-svg
                  :src="require('../assets/images/icons/email.svg')"
                  class="inline-block w-3 h-3 mr-2 text-jooycar-gray-medium"
                />
                {{ inspectionPerson.email }}
              </div>
            </div>
            <div class="h-full pt-4 pb-8 pl-6 bg-jooycar-gray-lightest">
              <inline-svg
                :src="require('../assets/images/icons/truck.svg')"
                class="w-6 h-6 mb-2 text-jooycar-gray-medium"
              />
              <div class="mb-2 font-semibold text-md">
                Datos para despacho
              </div>
              <div class="text-sm">
                <inline-svg
                  :src="require('../assets/images/icons/location.svg')"
                  class="inline-block w-4 h-4 mr-2 text-jooycar-gray-medium"
                />
                {{ deliveryFullStreet }}
              </div>
              <div class="text-sm">
                <div class="inline-block w-4 h-4 mr-2" />
                {{ deliveryMunicipalityAndRegion }}
              </div>
            </div>
            <div class="ml-8" />
          </div>
        </div>
        <price-summary
          variant="summary"
          :plan="selectedPlan"
          :distance-factor="selectedLiability.distanceFactor"
          :optionals="optionals"
        />
        <div class="mb-8">
          <label class="block text-center">
            <input
              id="terms-checkbox"
              class="mr-2 leading-tight"
              type="checkbox"
              v-model="acceptedTerms"
            >
            <span class="text-sm">
              Acepto los
              <a
                href="/terminos_y_condiciones.pdf"
                class="underline text-jooycar-blue"
                target="_blank"
                rel="noreferrer noopener"
              >términos y condiciones</a>
              ,
              <a
                href="/contrato_de_comodato.pdf"
                class="underline text-jooycar-blue"
                target="_blank"
                rel="noreferrer noopener"
              >contrato de comodato</a>
              y la
              <a
                href="/politica_de_privacidad.pdf"
                class="underline text-jooycar-blue"
                target="_blank"
                rel="noreferrer noopener"
              >autorización para el uso y tratamiento de mis datos personales</a>
              de Maneja tus KM
            </span>
          </label>
        </div>
        <div class="flex flex-col-reverse items-center mt-6 md:flex-row md:justify-center">
          <button
            @click="previousStep"
            class="block px-10 py-3 mt-2 font-semibold text-center text-white w-80 md:mt-auto md:mr-1 bg-jooycar-gray-dark"
            type="button"
          >
            VOLVER
          </button>
          <button
            id="submitBtn"
            class="block px-8 py-3 mt-auto font-semibold text-white w-80 bg-jooycar-blue"
            :class="{
              'cursor-default opacity-50': !acceptedTerms || loading || invalid,
              'hover:bg-jooycar-blue-dark': !(!acceptedTerms || loading ||invalid)
            }"
            :disabled="!acceptedTerms || loading || invalid"
          >
            {{ loading ? 'ENVIANDO INFORMACIÓN...' : 'AGREGAR TARJETA' }}
          </button>
        </div>
      </form>
    </ValidationObserver>
    <restart-form-prompt
      class="mb-16"
      v-if="leadError"
    />
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import { mapState, mapGetters } from 'vuex';
import _get from 'lodash.get';
import PriceSummary from '../components/price-summary';
import Stepper from '../components/stepper';
import AddressInput from '../components/address-input';
import DataDate from '../components/data-date.vue';
import DataInput from '../components/data-input.vue';
import DataSelect from '../components/data-select.vue';
import StepProgressBar from '../components/step-progress-bar.vue';
import RestartFormPrompt from '../components/restart-form-prompt';
import formatPhone from '../filters/formatPhone';
import dateFormatter from '../helpers/dateFormatter';

export default {
  components: {
    PriceSummary,
    Stepper,
    AddressInput,
    ToggleButton,
    DataDate,
    DataInput,
    DataSelect,
    RestartFormPrompt,
    StepProgressBar,
  },
  filters: {
    formatPhone,
  },
  data() {
    return {
      acceptedTerms: false,
      loading: false,
      differentInsuredAndPayingPersonData: false,
      payingPerson: {},
      isPayingPersonCompany: false,
      savedCompanyToggleData: {},
      genders: [
        {
          label: 'Masculino',
          value: 'M',
        },
        {
          label: 'Femenino',
          value: 'F',
        },
      ],
      payingAddress: {},
    };
  },
  methods: {
    previousStep() {
      this.$analytics.logEvent(
        `${this.$analyticsEvents.custom.previousStage.eventName}_3`,
        {
          name: this.$analyticsEvents.stages.c1
        }
      );
      this.$router.push({ name: 'C1' });
    },
    toggleDifferentInsuredAndPayingPersonData() {
      this.differentInsuredAndPayingPersonData = !this.differentInsuredAndPayingPersonData;
    },
    toggleIsPayingPersonCompany() {
      this.isPayingPersonCompany = !this.isPayingPersonCompany;
      if (this.isPayingPersonCompany) {
        const { gender, lastName1, lastName2 } = this.payingPerson;
        this.savedCompanyToggleData = {
          gender, lastName1, lastName2,
        };
        this.payingPerson.gender = 'E';
        this.payingPerson.lastName1 = '-';
        this.payingPerson.lastName2 = '-';
        this.payingPerson.birthDate = new Date('01-01-1900')
      } else {
        const { gender, lastName1, lastName2 } = this.savedCompanyToggleData;
        this.payingPerson = {
          ...this.payingPerson, gender, lastName1, lastName2,
          birthDate: new Date(),
        };
      }
    },
    goToPayment() {
      this.loading = true;
      if (this.differentInsuredAndPayingPersonData) {
        this.$store.dispatch('updateLeadAndRedirect', { leadToUpdate: this.leadToUpdate, redirectToPayment: true });
      } else {
        this.$store.dispatch('redirectToPayment');
      }
    },
    itsMyStage () {
      let stageInHistory = false
      const correctStage = this.lead.currentHistory.find(history => history.stage == this.viewStage) > 0;

      this.lead.currentHistory.forEach(history => {
        if (history.stage == this.viewStage) stageInHistory = true;
      });

      if (!correctStage && !stageInHistory) {
        this.$router.push({ name: this.lead.currentStage });
      }
    },
  },
  computed: {
    ...mapState({
      insuredPerson: state => _get(state.lead.stages, 'insuredPerson', {}),
      insuredVehicle: state => _get(state.lead.stages, 'insuredVehicle', {}),
      deliveryAddress: state => _get(state.lead.stages, 'deliveryAddress', {}),
      inspectionPerson: state => _get(state.lead.stages, 'inspectionPerson', {}),
      lead: state => state.lead,
      stages: state => state.lead.stages,
      leadError: state => state.leadError,
    }),
    ...mapGetters([
      'carName',
      'insuredPersonFullName',
      'insuredPersonFullMobile',
      'inspectionPersonFullMobile',
      'deliveryRegion',
      'deliveryMunicipality',
      'selectedQuote',
    ]),
    selectedLiability() {
      return this.selectedPlan.liabilities.find((liability) => liability.code === this.selectedQuote.liability);
    },
    selectedPlan() {
      return this.$store.getters.selectedPlan();
    },
    optionals() {
      const optionalsArray = Object.values(this.selectedPlan.optionals);
      let relatedOptionals = [];

      optionalsArray.forEach(optional => {
        const alternatives = Object.values(optional.alternatives);
        alternatives.forEach(alternative => {
          relatedOptionals = relatedOptionals.concat(alternative.relatedOptionals);
        });
      });

      return this.selectedQuote.optionals.map(
        optional => relatedOptionals.find(relatedOptional => relatedOptional.metadata.optionalCode === optional),
      );
    },
    deliveryFullStreet() {
      return [
        this.deliveryAddress.street, this.deliveryAddress.streetNumber, this.deliveryAddress.unitNumber,
      ].join(' ');
    },
    deliveryMunicipalityAndRegion() {
      if (this.deliveryMunicipality.name && this.deliveryRegion.name) {
        return `${this.deliveryMunicipality.name}, ${this.deliveryRegion.name}`;
      }

      return '';
    },
    formattedBirthDate() {
      const date = this.payingPerson.birthDate;

      return dateFormatter(date, '-', true);
    },
    leadToUpdate() {
      return {
        ...this.lead,
        stages: {
          ...this.stages,
          payingPerson: {
            ...this.payingPerson,
            birthDate: this.formattedBirthDate,
          },
          payingAddress: {
            ...this.payingAddress,
          },
        },
      };
    },
  },
  mounted() {
    this.itsMyStage();

    if (!this.deliveryRegion.name) this.$store.dispatch('getRegions');
    if (!this.deliveryMunicipality.name) this.$store.dispatch('getMunicipalities');
  },
};
</script>

<style lang="scss">
  .transparent-text {
    color: transparent;
  }
</style>
