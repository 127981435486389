const FORMAT_LENGTH = 2;

export default function dateFormatter(date, separator = '/', inverted = false) {
  if (!date) return null;

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();

  if (day.length < FORMAT_LENGTH) day = `0${day}`;
  if (month.length < FORMAT_LENGTH) month = `0${month}`;

  const dateArray = inverted ? [year, month, day] : [day, month, year];

  return dateArray.join(separator);
}
