const FIRST_SPACE_INDEX = 1;
const SECOND_SPACE_INDEX = 5;

export default function formatPhone(phoneNumber) {
  if (!phoneNumber) return null;

  const stringNumber = phoneNumber.toString();

  return [stringNumber.substring(0, FIRST_SPACE_INDEX),
    stringNumber.substring(FIRST_SPACE_INDEX, SECOND_SPACE_INDEX),
    stringNumber.substring(SECOND_SPACE_INDEX)].join(' ');
}
