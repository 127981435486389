<template>
  <div>
    <div class="flex flex-col mt-6 md:flex-row">
      <data-select
        class="flex flex-col flex-1 md:mr-4"
        label="Comuna*"
        :name="`${inputIdPrefix}-municipality`"
        placeholder="Seleccionar"
        v-model="municipality"
        :options="municipalityOptions"
        rules="required"
        :disabled="disableMunicipality"
      />
      <data-input
        class="flex flex-col flex-1 mt-3 md:mt-0"
        label="Calle*"
        :name="`${inputIdPrefix}-street`"
        placeholder="Huérfanos"
        v-model="value.street"
        rules="required"
      />
    </div>
    <div class="flex flex-col md:flex-row md:mt-3">
      <data-input
        class="flex flex-col flex-1 mt-3 md:mt-0 md:mr-4"
        label="Nº Vivienda*"
        :name="`${inputIdPrefix}-number`"
        placeholder="1400"
        v-model="value.streetNumber"
        rules="required"
        :max-length="10"
      />
      <data-input
        class="flex flex-col flex-1 mt-3 md:mt-0"
        label="Nº Depto/Block"
        :name="`${inputIdPrefix}-apartment`"
        placeholder="48"
        v-model="value.unitNumber"
        :max-length="10"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DataInput from './data-input';
import DataSelect from './data-select';

export default {
  components: {
    DataInput,
    DataSelect,
  },
  props: {
    value: { type: Object, required: true },
    disableMunicipality: { type: Boolean, default: false },
    inputIdPrefix: { type: String, required: true },
  },
  computed: {
    ...mapState(['municipalities']),
    municipalityOptions() {
      return this.toSelectOptions(this.municipalities);
    },
    selectedMunicipality() {
      return this.municipalities.find(municipality => municipality.id === this.value.municipality);
    },
    municipality: {
      get() {
        return this.value.municipality;
      },
      set(value) {
        this.value.municipality = value;
        this.value.state = this.selectedMunicipality.stateId;
      },
    },
  },
  methods: {
    toSelectOptions(list) {
      return list.map((item) => ({ label: item.name, value: item.id }));
    },
  },
  mounted() {
    if (this.municipalityOptions.length === 0) {
      this.$store.dispatch('getMunicipalities');
    }
  },
};
</script>
